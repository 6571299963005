<template>
  <div class="g_page_box" style="background: #F7F7F7;">
    <div class="no-data" v-if="list.length===0&&!loading">
      <img width="185" src="../../assets/img/order/no-date.png"/>
      暂无数据
    </div>
    <div class="g_main_content order" v-else>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="list">
        <van-list
          :offset="100"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多数据了"
          @load="onLoad"
        >
          <div class="comment">
            <div class="title"><span>患者评价({{total}})</span></div>
            <div v-for="(i,index) in list" :key="index + 'comment'">
              <div class="header">
                <div class="img">
                  <img v-if="i.avatar_url" width="100%"  height="100%" :src="i.avatar_url"/>
                  <img v-else width="100%"  height="100%" src="../../assets/img/online/doctor_default.png"/>
                </div>
                <span>{{i.nickname.substr(0,1) + '**'}}</span>
                <van-rate readonly  :size="10" v-model="i.score" void-icon="star" class="star" color="#ffd21e"/>
              </div>
              <div class="comment-content">
                {{i.content}}
              </div>
              <div class="date_str">{{i.date_str}}</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import {format_money} from '../../utils/format'
import { get_comment } from './service'
export default {
  data () {
    return {
      list: [],
      current_list: [],
      refreshing: false,
      finished:false,
      loading: false,
      timer: null,
      page_size: 10,
      page: 0,
      total: 0,
      error: false,
      name_timer: null
    }
  },
  components: {

  },
  watch:{

  },
  created() {
    this.search_comment()
  },
  methods: {
    format_money,
    hander_time(time) {
      if(time<3600) {
        return Math.floor(time/60) + '分钟内'
      } else {
        return Math.floor(time/3600) + '小时内'
      }
    },
    hander_search() {
      this.current_list = []
      this.list = []
      this.page = 0
      this.total = 0
      this.finished = false
      this.search_comment()
    },
    onLoad() {
      if(this.timer) clearTimeout(this.timer)
      if (this.refreshing) {
        this.loading = true
        this.list = []
        this.current_list = []
        this.page = -1
        this.refreshing = false
      }
      if(this.list.length>0&&this.list.length === this.total || this.error || this.total === 0) {
        this.finished = true
        this.error = false
        this.loading = false
        return
      }

      this.timer = setTimeout(()=>{
        this.page = this.page + 1
        this.search_comment()
      },1000)
    },
    onRefresh() {
      this.finished = false
      this.loading = false
      this.onLoad()
    },
    async search_comment () {
      let {page,page_size} = this
      const {id} = this.$route.query
      try {
        this.loading = true
        const {data} = await get_comment({id,params:{page,page_size}})
        this.current_list = data.data
        for(let i of data.data) {
          i.score = Math.ceil(i.score)
        }
        this.total = data.total_elements || 0
        this.list = this.list.concat(data.data)
      }catch(e) {
        this.error = true
        console.log(e)
      } finally {
        this.loading =  false
      }

    }
  }
}
</script>
<style scoped>
.order {
  padding-top: 10px;

}
.list {
  padding:0 10px;
}
.search-area {
  padding: 10px 0 0;
  background: #fff;
  position: relative;
  z-index: 100;
  & .inner {
    height: 34px;
    border-radius: 18px;
    background: #F7F8FA;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 0 16px;
  }
  & .input-content {
    height: 100%;
    background: #F7F8FA;
    &::placeholder{
      font-size: 14px;
      color: #C8C9CC;
      opacity: 0.89;
    }
  }
  & .name-icon {
    font-size: 18px;
    opacity: 0.71;
    color: #969799;
    margin-right: 6px;
    transform: translateY(2px);
  }
}
.tab {
  padding:0 16px;
  display: flex;
  height: 50px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #323233;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  border-top: 1px solid rgba(112, 112, 112, 0.1);

  & >li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-right: 10px;
    & span {
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
    }
    & .tab-select {
      color: rgba(150, 151, 153, 1);
      padding-left: 10px;
    }
    &.active{
      color: rgba(24, 144, 255, 1);
      & .tab-select {
        color: rgba(24, 144, 255, 1);
        transform: rotate(180deg);
      }
    }

  }
}
.dropdown-dialog {
  position: fixed;
  right: 0;
  left: 0;
  bottom:0;
  z-index: 10;
  overflow: hidden;
  top: 105px;
  display: none;
  &.show-dialog {
    display: block;
  }
  & .inner-dialog {
    position: absolute;
    animation-duration: 0.2s;
    right: 0px;
    bottom: 10px;
    z-index: 80;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
  }
  & .dropdown-content {
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    max-height: 60%;
    min-height: 20%;
    position: absolute;
    transition-duration: 0.2s;
    transition: transform .3 ease-out,top .3s ease-out;
    overflow-y: auto;
    }
}
.comment {
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 15px 8px;
  & .title {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    color: #0088FF;
    align-items: center;
    & >span {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      color: #242938;
      flex: 1;
    }
  }
  & .header {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    color: #323233;
    padding: 14px 0 7px;
    & .img {
      width: 20px;
      height: 20px;
      opacity: 1;
      border-radius: 30px;
      pointer-events: none;
      margin-right: 10px;
    }
    & >span {
      flex:1;
    }
  }
  & .comment-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #323233;
    padding-bottom: 6px;
    padding-left: 30px;
  }
  & .date_str {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #999999;
    padding-left: 30px;
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color:rgba(112, 112, 112, 0.5);
  }
}
</style>
