import api from '../../utils/api'

export async function get_comment (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_elements: 7,
  //         data: [
  //           {
  //             online_doctor_id: 1,
  //             patient_name:'张三',
  //             patient_id: 1,
  //             content: '遇见医生之前差点放弃一条小生命，也是家长对疾病的无知！',
  //             score: 5,
  //             date_str: '两周前'
  //           },
  //           {
  //             online_doctor_id: 1,
  //             patient_name:'张三',
  //             patient_id: 2,
  //             content: '遇见医生之前差点放弃一条小生命，也是家长对疾病的无知！',
  //             score: 5,
  //             date_str: '两周前'
  //           },
  //           {
  //             online_doctor_id: 1,
  //             patient_name:'张三',
  //             patient_id: 3,
  //             content: '遇见医生之前差点放弃一条小生命，也是家长对疾病的无知！',
  //             score: 5,
  //             date_str: '两周前'
  //           }
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/doctors/${payload.id}/comments`,{params: payload.params})
}
